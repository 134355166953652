import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Members = (props) => (
    <header id="members" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <Link to="/"><span className="icon fa-book"></span></Link>
        </div>
        <div className="content">
            <div className="inner">
                <h1>New Haven Speculative Fiction Workshop</h1>
                <h3>Members</h3>
                <p>This page is blank. Some of the things you might <br />
                expect to find here are a list of members, bios and <br />
                links to personal websites and bibliographies, a link <br />
                to our private discord channel where we coordinate <br />
                meet-ups and share stories, or a method for people <br />
                to join.</p>
                <p>But no. This page is blank.</p>
                <p>Interested in joining? Email us at <br />
                [our domain name] @gmail.com</p>
                <p><Link to="/">Go back to the homepage</Link></p>
            </div>
        </div>
        <nav>
            <ul>
                <li><Link to="/introduction">About</Link></li>
                <li><Link to="/members">Members</Link></li>
                <li><Link to="/rules">Rules</Link></li>
                <li><Link to="/contact">Contact</Link></li>
            </ul>
        </nav>
    </header>
)

Members.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Members




 
